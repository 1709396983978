<template>
  <v-container
      fluid
      tag="section"
  >
    <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3" v-if="notifications">
      <v-col cols="12" class="mb-3">
        <p class="text-h5 main-font-bold">{{ $t('notifications') }}</p>
        <div class="subtitle-1 font-weight-light main-grey">
          {{ $t('notifications_subtitle') }}
        </div>
      </v-col>
      <v-col cols="12" v-if="notifications.data.length">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">{{ $t('title') }}</th>
              <th class="text-left">{{ $t('message') }}</th>
              <th class="text-left">{{ $t('date') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(notification, i) in notifications.data" :key="notification.id">
              <td>{{ notifications.meta.from + i }}</td>
              <td>{{ notification.title }}</td>
              <td>{{ notification.content }}</td>
              <td>{{ $helpers.formatDate(notification.created_at, 'DD.MM.YYYY') }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col v-else justify="center" align="center" cols="12">
        <v-icon x-large color="grey">mdi-alert-rhombus-outline</v-icon>
        <div class="mt-5 grey--text text-subtitle-1">{{ $t('empty_notifications') }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      notifications: null,
      active: false,
      activeLoading: false,
    }
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      this.loading = true
      this.$store.dispatch('getNotifications')
          .then(resp => {
            this.notifications = resp
            this.loading = false
          })
    },
  }
};
</script>
