<template>
  <section class="d-flex flex-column">
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>
    <v-row align="center">
      <v-col
          cols="12"
          md="6"
          align="center"
          justify="center"
          class="header-col"
      >
        <div class="header-text">
          <h1>{{ product.title }}</h1>
          <div class="main-font">
            {{ product.description }}
          </div>
          <div class="pt-5">
            <v-btn
                v-if="!$store.getters.isLoggedIn"
                elevation="5"
                min-width="0"
                x-large
                color="primary"
                @click="showRegister = true"
            >
              <span>Kostenlos registrieren</span>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-img v-if="product.image" :src="product.image" width="100%" min-height="calc(100vh - 90px)"></v-img>
        <div
            v-else-if="product.benefitPartners"
            class="d-flex align-center pa-3"
            :class="{ 'pr-8': !$vuetify.breakpoint.smAndDown }"
            :style="{ minHeight: $vuetify.breakpoint.smAndDown ? 'auto' : 'calc(100vh - 90px)' }"
        >
          <v-row>
            <v-col cols="12" md="6" v-for="(partner, index) in product.benefitPartners" :key="index">
              <v-hover v-slot="{ hover }">
                <v-card
                    v-if="partner.target"
                    class="d-flex justify-center flex-column"
                    height="100%"
                    :elevation="hover ? 7 : 5"
                    :href="partner.target"
                    target="_blank"
                >
                  <div class="pb-4 pl-4 pr-4 pt-6" v-if="partner.logo && partner.name">
                    <v-img
                        :src="partner.logo"
                        :alt="partner.name"
                        :max-height="60"
                        :contain="true"
                    />
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        @close-login-modal="showLogin = false"
        @close-register-modal="showRegister = false"
    />
  </section>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import store from "@/store";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      product: {},
      products: [
        {
          name: 'persoenliche-botschaft',
          title: this.$root.$t('texts.products.personal_message.title'),
          description: this.$root.$t('texts.products.personal_message.description'),
          image: '/assets/img/home/p_persoenliche_botschaft.jpg'
        },
        {
          name: 'checkliste',
          title: this.$root.$t('texts.products.checklist.title'),
          description: this.$root.$t('texts.products.checklist.description'),
          image: '/assets/img/home/p_checkliste.jpg'
        },
        {
          name: 'tresor',
          title: this.$root.$t('texts.products.digital_safe.title'),
          description: this.$root.$t('texts.products.digital_safe.description'),
          image: '/assets/img/home/p_tresor.jpg'
        },
        {
          name: 'der-letzte-wunsch',
          title: this.$root.$t('texts.products.last_wish.title'),
          description: this.$root.$t('texts.products.last_wish.description'),
          image: '/assets/img/home/p_der_letzte_wunsch.jpg'
        },
        {
          name: 'vorteilspartner',
          title: this.$root.$t('texts.products.benefit_partners.title'),
          description: this.$root.$t('texts.products.benefit_partners.description'),
          benefitPartners: [
            {
              target: 'https://geras24.juradirekt.com',
              name: this.$t('benefit_partners_list.juradirekt.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/juradirekt.png`
            },
            {
              target: 'https://safe-id.eu/discount/',
              name: this.$t('benefit_partners_list.saveid.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/safeid.png`
            },
            {
              target: 'https://life4.style/',
              name: this.$t('benefit_partners_list.life4style.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/life4style.png`,
            },
            {
              target: 'https://www.allvest.de/',
              name: this.$t('benefit_partners_list.allvest.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/allvest.png`
            },
            {
              target: 'https://www.deinsportsfreund.de/',
              name: this.$t('benefit_partners_list.dsf.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/dsf.png`,
            },
            {
              target: 'https://syncro24.de/',
              name: this.$t('benefit_partners_list.syncro24.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/syncro24.png`,
            },
            {
              target: 'https://www.wechselpilot.com/',
              name: this.$t('benefit_partners_list.wechselpilot.name'),
              logo: `${store.getters.frontUrl}/assets/img/home/wechselpilot.png`,
            },
          ]
        },
      ],
      showLogin: false,
      showRegister: false
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    // Re-fetch when route changes
    '$route': 'getData'
  },
  methods: {
    getData() {
      this.product = this.products.find(obj => {
        return obj.name === this.$route.params.product
      })
    }
  }
}
</script>
